<template>
  <div
    class="toast-pig pointer-events-none absolute left-0 flex gap-3 md:gap-5"
  >
    <!-- Pig -->
    <NuxtImg
      ref="pig"
      src="/images/logo-icon-small.png"
      class="h-20 w-20"
      width="100"
      height="100"
    />

    <!-- Dialog bubble -->
    <div class="relative">
      <div ref="dialogBubble">
        <div class="z-30 w-max md:w-[275px]">
          <div
            class="dialog-bubble rounded-[15px] border border-[#16114F] bg-white px-[4px] py-5 shadow-[2px_2px_0px_0px_#16114F] md:rounded-[10px] md:border-2 md:px-6 md:py-[18px]"
          >
            <div class="flex flex-col text-left">
              <span
                class="font-Kontesa text-base uppercase leading-[14px] -tracking-[0.01em] text-[#16114F] md:text-[16px] md:-tracking-[0.02em]"
              >
                <slot />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const pig = ref();
const dialogBubble = ref();

interface ToastPigProps {
  open: boolean;
}

const props = defineProps<ToastPigProps>();

watch(
  () => props.open,
  () => {
    animate();
  },
);

onMounted(() => {
  animate();
});

const animate = () => {
  if (props.open) {
    pigAnimation.apply("show");
    bubbleAnimation.apply("show");
  } else {
    pigAnimation.apply("fadeOut");
    bubbleAnimation.apply("fadeOut");
  }
};

const defineAnimation = (onComplete: () => void) => {
  return {
    initial: {
      opacity: 0,
      y: 0,
    },
    show: {
      opacity: 1,
      y: -90,
      transition: {
        type: "spring",
        stiffness: 600,
        damping: 20,
        mass: 0.8,
        delay: 75,
        onComplete,
      },
    },
    levitate: {
      y: -80,
      transition: {
        duration: 2000,
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
    },
    fadeOut: {
      opacity: 0,
      y: -60,
      transition: {
        duration: 300,
        ease: "backOut",
      },
    },
  } as const;
};

const pigAnimation = useMotion(
  pig,
  defineAnimation(() => {
    pigAnimation.apply("levitate");
  }),
);
const bubbleAnimation = useMotion(
  dialogBubble,
  defineAnimation(() => {
    bubbleAnimation.apply("levitate");
  }),
);
</script>

<style lang="scss" scoped>
.dialog-bubble {
  &::before {
    content: "";
    position: absolute;
    top: 26%;
    left: -4.9%;
    transform: translateX(-60%);
    width: 0;
    height: 0;
    border-width: 14px 14px 14px 23.5px;
    border-style: solid;
    border-color: transparent #16114f transparent transparent;
    z-index: -1;
    @media screen and (max-width: 767px) {
      border-width: 13px 13px 13px 9px;
      top: 15px;
      left: -4.5%;
      transform: translateX(-50%);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 30%;
    left: -3.8%;
    transform: translateX(-60%);
    border-width: 12px 12px 12px 21px;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent;
    z-index: 1;
    @media screen and (max-width: 767px) {
      border-width: 12px 12px 12px 8px;
      top: 16px;
      left: -3%;
      transform: translateX(-60%);
    }
  }
}
</style>
